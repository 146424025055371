import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, CompanyDetails } from 'types/schema';
import { footerMinHeight, headerHeight } from 'components/theme/measurements';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import H1 from 'components/H1';
import LinkButton from 'components/LinkButton';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import OverlayBox from 'components/OverlayBox';

export const pageQuery = graphql`
  query PayPageQuery {
    sanityPayPage(id: { eq: "-e2b0c3a1-6aaa-5e27-beac-8038987d1f82" }) {
      meta {
        title
        description
      }
      pageTitle
    }
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      companyName
      address {
        name
        addressLine1
        addressLine2
        city
        province
        postalCode
      }
    }
  }
`;
type QueryData = {
  sanityPayPage: BaseSanityPage;
  sanityCompanyDetails: Pick<CompanyDetails, 'companyName' | 'address'>;
};

const PayPage: React.FC<{ data: QueryData }> = ({ data }) => {
  const {
    meta: { title, description },
    pageTitle,
  } = data.sanityPayPage;
  const { companyName, address } = data.sanityCompanyDetails;
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/pay/' }}>
      <OuterWrap
        minHeight={`calc(100vh - ${headerHeight}px - ${footerMinHeight}px)`}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          backgroundImage: `url(https://images.unsplash.com/photo-1581559069235-da1c05a2dd1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1983&q=80)`,
          backgroundSize: 'cover',
          backgroundPosition: '40%',
        }}
      >
        <OverlayBox bg="rgba(255, 255, 255, 0.95)" />
        <Box position="relative">
          <H1>{pageTitle}</H1>
          <Grid
            gridTemplateColumns={['1fr', '1fr 1fr']}
            alignItems="start"
            gridColumnGap={8}
            mt={8}
          >
            <Box>
              <Box>
                1. By cheque made payable to &quot;Perks Law Group&quot; mailed to:
                <Box variant="header6Serif" pl={5} mt={2}>
                  {companyName}
                  <br />
                  {address.addressLine1}
                  {!!address.addressLine2 && <>, {address.addressLine2}</>}
                  <br />
                  {address.city}, {address.province}
                  <br />
                  {address.postalCode}
                </Box>
              </Box>
              <Box mt={4}>
                2. By Interac Email Money Transfer to:
                <Box variant="header6Serif" pl={5} mt={2}>
                  info@perkslawgroup.com
                </Box>
              </Box>
            </Box>
            <Flex flexDirection="column" alignItems="flex-start" mt={[2, 0]}>
              3. Or by credit card using following forms:
              <LinkButton mt={3} href="https://secure.lawpay.com/pages/perkslawgroup/operating">
                Pay an Invoice
              </LinkButton>
              <LinkButton mt={3} href="https://secure.lawpay.com/pages/perkslawgroup/trust">
                Pay a Retainer
              </LinkButton>
            </Flex>
          </Grid>
        </Box>
      </OuterWrap>
    </MainLayout>
  );
};

export default PayPage;
